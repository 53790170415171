<template>
  <div>
    <flixDashboard>
      <template v-slot:content>
        <div class="flix-container">
          <flixCheckPermissions role="customer" permission="3" error>
            <userStatistic />
          </flixCheckPermissions>
        </div>
      </template>
    </flixDashboard>
  </div>
</template>

<script>
export default {
  components: {
    userStatistic () { return import('@/components/userStatistic/') }
  }
}
</script>
